@import "@fontsource/ibm-plex-sans/index.css";
@import "@fontsource/ibm-plex-sans/400.css";
@import "@fontsource/ibm-plex-sans/400-italic.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40;

    --font-primary: "IBM Plex Sans", sans-serif;
}

html {
    font-size: 16px;
    height: 100vh;
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-primary), serif;
    line-height: 1.5;
    color: var(--dark-color);
    background-color: var(--light-color);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}


img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
    height: auto;
}

input,
button,
textarea,
select {
    font: inherit;
    color: inherit;
}